.component {
  & > .lottie {
    width: 100%;
    height: 100%;
  }
}

.lottie {
  & > * {
    width: 100%;
    height: 100%;
  }
}
