.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > .animation {
    flex: 1 1 auto;
    margin-top: 10vh;
  }

  & > .title {
    flex: 0 0 auto;
  }
}

.animation {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .lottie {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.title {
  padding: var(--size-md);

  @media (--viewport-md) {
    padding: var(--size-lg);
  }
}
