.component {
  position: relative;
  z-index: 0;
  pointer-events: none;

  &[data-context-visible='true'] {
    pointer-events: auto;
  }

  & > .kaartLaag {
    z-index: 0;
  }

  & > .pinsLaag {
    z-index: 1;
  }

  & > .floorPin {
    top: 0;
    left: 0;
    position: absolute;
  }

  & > .floorButton {
    width: 100%;
    height: 100%;
  }
}

.floorButton {
  color: transparent;

  &:focus {
    outline: 0;
  }

  & > * {
    width: 100%;
    height: 100%;
  }
}

.floorPin {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.componentImage {
  pointer-events: none;
}
