.componentBase {
  line-height: var(--line-height-normal);
  font-family: var(--font-family-base);
  border-radius: 30px;
  font-size: var(--font-size-14);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--size-18);
  color: currentColor;
  background-color: var(--color-white);

  & > .body {
    margin-right: var(--size-xs);
  }
}

.text {
  display: flex;
  flex-direction: column;
  padding: var(--size-sm);
}

.iconRight {
  transition: transform var(--duration-sm) ease-in-out;

  & > * {
    width: 100%;
    height: auto;
  }
}
