.page {
  position: relative;
  display: flex;

  &.isFloor {
    background-color: var(--floor-background-color);
  }

  & > .map {
    flex-grow: 1;
    width: 100%;
    height: 100vh;
  }

  & > .story {
    position: absolute;
    bottom: 5vh;
    left: 0;
    width: 100%;
  }
}

.story {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  will-change: transform;

  & > * {
    width: 100%;
  }
}

.stories {
  & > :not(:last-child) {
    margin-bottom: var(--size-lg);
  }
}

.map {
  & > * {
    height: 100%;
  }
}
