.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  background: var(--color-gray-90);

  & > .video {
    position: relative;
    z-index: -1;
    height: 100%;
    flex: 1 1 auto;
  }

  & > .title {
    position: absolute;
    bottom: 0;
    z-index: 1;
    flex: 0 0 auto;
  }

  & > .buffering {
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--size-lg);
    height: var(--size-lg);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  }
}

.title {
  color: var(--color-white);
  padding: var(--size-md);

  & > :not(:last-child) {
    margin-bottom: var(--size-sm);
  }

  @media (--viewport-sm) {
    padding: var(--size-lg);
  }
}

.video {
  position: relative;

  & > .videoLayout,
  & > .posterLayout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.videoLayout {
  object-fit: contain;
}

.buffering {
  border: 2px solid var(--color-white);
  border-right-color: transparent;
  border-radius: 50%;
  pointer-events: none;
  animation: rotate infinite linear var(--duration-lg);
  will-change: transform;
}

@keyframes rotate {
  from { transform: rotate(0turn); }
  to { transform: rotate(1turn); }
}
