.component {
  position: relative;
  z-index: 0;

  & > .image,
  & > .thumb {
    width: 100%;
    height: auto;
  }

  & > .isCover {
    height: 100%;
  }

  &.hasSize {
    & > .thumb {
      position: absolute;
      z-index: 1;
    }
  }
}

.thumb,
.image {
  &.isCover {
    object-fit: cover;
  }
}
