.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: var(--size-xl) var(--size-md) var(--size-md);

  @media (--viewport-md) {
    padding: var(--size-xl) var(--size-lg) var(--size-lg);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-lg);
  }
}
