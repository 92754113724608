.componentBase {
  display: block;
  color: currentColor;
  line-height: var(--line-height-extra-small);
  font-family: var(--font-family-alt);
  letter-spacing: 0.3px;
}

.componentSm {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-600);
  font-family: var(--font-family-base);
}

.componentMd {
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-600);
  font-family: var(--font-family-base);
}

.componentUppercaseLg {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-400);
  text-transform: uppercase;
  font-family: var(--font-family-alt);

  @media (--viewport-lg) {
    font-size: var(--font-size-32);
  }
}

.componentUppercaseXl {
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-400);
  text-transform: uppercase;
  font-family: var(--font-family-alt);

  @media (--viewport-lg) {
    font-size: var(--font-size-58);
  }
}

.componentUppercaseXxl {
  font-size: var(--font-size-58);
  font-weight: var(--font-weight-400);
  text-transform: uppercase;
  font-family: var(--font-family-alt);

  @media (--viewport-lg) {
    font-size: var(--font-size-70);
  }
}
