.componentBase {
  color: inherit;
  text-decoration: none;
}

.componentText {
  text-decoration: underline;

  &:hover,
  &:focus { text-decoration: none; }
}

.componentBlock {
  color: inherit;
  display: block;
}
