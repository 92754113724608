.componentBase {
  line-height: var(--line-height-normal);
}

.componentLg {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-300);

  @media (--viewport-sm) {
    font-size: var(--font-size-28);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-md);
  }
}

.componentMd {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-300);

  @media (--viewport-sm) {
    font-size: var(--font-size-20);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-sm);
  }
}

.componentSm {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);

  @media (--viewport-sm) {
    font-size: var(--font-size-18);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-xs);
  }
}
