.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  background: var(--color-gray-90);

  & > .image {
    flex: 0 1 auto;
    margin: var(--size-120) var(--size-lg) var(--size-md);
  }

  & > .details {
    flex: 1 1 auto;
  }
}

.details {
  padding: 0 var(--size-lg) var(--size-lg);

  & > :not(:last-child) {
    margin-bottom: var(--size-sm);
  }
}

.image {
  position: relative;
  border-radius: 999px 999px 0 0;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    height: 0;
    padding-bottom: calc(100% / (17 / 20));
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


