.component {
  position: relative;
  z-index: 0;

  & > .chapter {
    height: 100%;
  }

  & > .ui {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.ui {
  display: flex;
  flex-direction: column;

  & > * {
    max-width: 100%;
  }
}

.chapter {
  overflow: hidden;
}

.componentHeader {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-areas:
    'pagination pagination  pagination'
    'avatar     header close';
  grid-template-columns: auto 1fr auto;
  grid-gap: var(--size-sm2) var(--size-sm);
  color: white;
  will-change: transform;
  padding: var(--size-md);

  @media (--viewport-sm) {
    padding: var(--size-lg);
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  }

  & > * {
    max-width: 100%;
    min-width: 0;
  }

  & > .pagination {
    grid-area: pagination;
  }

  & > .close {
    grid-area: close;
  }

  & > .avatar {
    grid-area: avatar;
  }

  & > .header {
    grid-area: header;
  }
}

.heading {
  font-size: var(--font-size-14);
  line-height: 1.3;
}

.description {
  font-size: var(--font-size-10);
}

.avatar {
  border-radius: 50%;
  width: 38px !important;
  height: 38px !important;
}

.componentPagination {
  display: flex;

  & > * {
    flex: 1 1 auto;
  }

  & > :not(:first-child) {
    margin-left: 4px;

    @media (--viewport-xs) {
      margin-left: 8px;
    }
  }
}

.item {
  background-color: rgba(255, 255, 255, 0.3);
  height: 2px !important;
  overflow: hidden;

  & > .fill {
    width: 100%;
    height: 100%;
  }
}

.fill {
  background-color: white;
  transform-origin: center left;
}

.close {
  padding: var(--size-sm);
  color: white;

  & > * {
    display: block;
    width: 1.6rem;
    height: 1.6rem;

    @media (--viewport-lg) {
      width: auto;
      height: auto;
    }
  }
}

.description {
  & > .heading {
    margin-bottom: 2px;
  }
}
