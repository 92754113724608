.component {
  --max-width-map: max(50vw, 1600px);

  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none;
  overflow: hidden;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  & > .draggableMap {
    flex: 0 0 auto;
    width: var(--max-width-map);
  }

  & > .zoomButtonsLayout {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;

    @media (--viewport-md) {
      top: unset;
      bottom: 0;
    }
  }
}

.map {
  position: relative;

  & > .pinsOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--max-width-map);
    height: 100%;
  }
}

.draggableMap {
  touch-action: none;
}

.componentPins {
  position: relative;

  & > .floorPin {
    position: absolute;
  }
}

.componentZoomButtons {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--size-8);
  padding: var(--size-40) var(--size-24);

  @media (--viewport-sm) {
    flex-direction: row;
    gap: var(--size-12);
  }

  @media (--viewport-md) {
    padding: var(--size-24);
  }
}

.componentZoomButton {
  padding: var(--size-8);
  border-radius: 50%;
  color: var(--color-black);
  background-color: var(--color-white);
  box-shadow: var(--shadow-md);
  transition: background-color var(--duration-md) ease;

  &:disabled {
    background-color: var(--color-gray-300);
  }
}

.iconContainer {
  display: block;
  transition: opacity var(--duration-md) ease;

  &.disabled {
    opacity: 0.5;
  }
}
