.component {
  position: relative;
  display: flex;
  justify-content: center;
  width: 1px !important;
  height: 1px !important;
  -webkit-tap-highlight-color: transparent;
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.1));

  &:focus {
    outline: 0;
  }

  & > .layout {
    position: absolute;
    bottom: 0;
    width: 35px !important;
    height: 50px !important;
  }
}

.layout {
  position: relative;
  display: flex;
  justify-content: center;

  & > .icon {
    position: absolute;
    bottom: 50%;
    width: 30px;
  }

  & > .circleIcon {
    position: absolute;
    top: 8%;
    height: 27px;
    width: 27px;
  }
}

.circleIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: transform var(--duration-xs) ease;
  border: 2px solid var(--color-white);
  position: relative;

  &::before {
    content: '';
    width: 13px;
    height: 13px;
    border-radius: 100%;
    background-color: currentColor;
  }

  &.isActive {
    transform: scale(2.5);
    top: -14%;

    &::before {
      display: none;
    }
  }
}

.icon {
  color: var(--color-black);
  border-radius: 100%;
  opacity: 0;

  &.isActive {
    transition: opacity var(--duration-md) ease;
    opacity: 1;
    transform: scale(1.5);
  }
}
