.root,
.background {
  background-color: var(--color-red-700);

  @media print {
    background-color: var(--color-white);
  }
}

.root,
.color {
  color: var(--color-white);

  @media print {
    color: var(--color-black);
  }
}

.accent {
  color: var(--color-red-300);
}

.primary {
  background-color: var(--color-red-300);

  @media print {
    background-color: var(--color-gray-300);
  }
}

.onPrimary {
  color: var(--color-black);

  @media print {
    color: var(--color-black);
  }
}
