.page {
  & > .map {
    z-index: -1;
    width: 100%;
    height: 100vh;
  }
}

.storylines {
  & > :first-child {
    margin-bottom: var(--size-60);
  }
}

.map {
  & > * {
    height: 100%;
  }
}
