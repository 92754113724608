.component {
  position: relative;
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: var(--size-sm);
  overflow: hidden;
  box-shadow: var(--shadow-md);

  & > .cover {
    order: -1;
  }

  & > .dismiss {
    position: absolute;
    top: var(--size-18);
    right: var(--size-18);
  }
}

.cover {
  position: relative;

  &::before {
    content: '';
    height: 0;
    display: block;
    padding-bottom: calc(100% / (16 / 9));
  }

  & > .coverImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.content {
  padding: var(--size-24);

  & > :not(:first-child) {
    margin-top: var(--size-md);
  }

  & > .button {
    width: 100%;
    margin-top: var(--size-24);
  }
}

.header {
  & > :not(:first-child) {
    margin-top: var(--size-xs);
  }
}

.description {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: 1;
}

.dismiss {
  color: white;
}
