.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-gray-90);

  & > .title {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  & > .image {
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  & > .button {
    position: absolute;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  }
}

.title {
  color: var(--color-white);
  padding: var(--size-md);

  @media (--viewport-md) {
    padding: var(--size-lg);
  }
}

.image {
  position: relative;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
